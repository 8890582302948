import * as React from "react"
import type { HeadFC } from "gatsby"
import "normalize.css"

import "../styles/global.css"
import { InsubHeader } from "../components/InsubHeader"
import { QuoteBanner } from "../components/QuoteBanner"
import { AboutBanner } from "../components/AboutBanner"
import { Footer } from "../components/Footer"

const IndexPage = () => {

  return (
    <>
      <InsubHeader />
      <QuoteBanner />
      <AboutBanner />
      <Footer />
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Insub Audio - Event Production - Home</title>
