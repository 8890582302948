import React, { FC } from "react"
import styled from "@emotion/styled"

import logo from "../../images/insub/logo.svg"

const Container = styled.div`
  width: 100vw;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 20px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
`;

const Logo = styled.img`
  height: 100px;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
  font-size: 24px;
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: 10px;

  @media (max-width: 800px) {
    font-size: 20px;
  }

  @media (max-width: 635px) {
    font-size: 18px;
  }

  @media (max-width: 460px) {
    font-size: 16px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

export const QuoteBanner: FC = () => {

  return <Container>
    <LogoContainer>
      <Logo src={logo} alt="Insub Audio" />
    </LogoContainer>
    <ContactContainer>
      <div>
        <Text>Contact <strong>Insub Audio</strong> for a free quote</Text>
        <Text><strong>info@insubaudio.co.uk</strong></Text>
      </div>
    </ContactContainer>
  </Container>;
}